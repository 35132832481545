import React, {useState, useEffect} from "react";
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {cssCleanClass, isMobile} from "../../Utils/functions"

const BannerOrA4 = ({node, wrapperRef}) => {
    const [maxWidth, setMaxWidth] = useState(332);
    const [maxHeight, setMaxHeight] = useState(470);
    
    let width = 0,
        height = 0,
        calcHeight = 0,
        verticalImage = false;

    if (node.image) {
        width = node.image.width;
        height = node.image.height;
        verticalImage = (width/height) < 1
        calcHeight = height * maxWidth / width;

        if (verticalImage && calcHeight > maxHeight) {
            setMaxWidth(maxHeight * maxWidth / calcHeight);
        }
    }

    useEffect(() => {
        if (verticalImage && wrapperRef.current) {
            setMaxHeight(isMobile() ? 240 : wrapperRef.current.offsetHeight)
        }
    }, [])

    useEffect(() => {
        if (verticalImage) {
            setMaxWidth(maxHeight * maxWidth / calcHeight);
        }
    }, [maxHeight])

    return (
        <>
            <div className="card-image relative h-60 md:h-full">
                {verticalImage ? (
                    <div className="block figure relative overflow-hidden bg-gray-100 h-full">
                        <GatsbyImage
                            image={node.relationships.imageBackground.localFile.childImageSharp.gatsbyImageData}
                            alt={node.title}
                            className="w-full h-full md:opacity-80 md:filter md:blur md:transform md:scale-105" 
                        />

                        <figure className="hidden md:block static md:absolute z-10 inset-x-0 top-1/2 md:transform md:-translate-y-1/2 m-auto" style={{maxWidth: `${maxWidth}px`, maxHeight: `${maxHeight}px`}}>
                            <GatsbyImage
                                image={node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                                alt={node.title}
                            />
                        </figure>
                    </div>
                ) : (
                    <div className="block figure relative h-full">
                        <GatsbyImage
                            image={node.relationships.imageBackground.localFile.childImageSharp.gatsbyImageData}
                            alt={node.title}
                            className="w-full h-full"
                        />
                    </div>
                )}
            </div>
            <div className="card-content px-2 md:px-12 py-6 md:absolute left-0 right-0 bottom-0 border-b-20 md:border-b-0 border-primary-light border-opacity-50 z-10">
                <span className={`tag theme theme-${cssCleanClass(node.relationships.tags.color)}`}>
                    <Link to={`/actualites${node.relationships.tags.path.alias}`} className="text-gray-500 md:text-white">{node.relationships.tags.name}</Link>
                </span>

                <div className="title text-lg md:text-2xl font-semibold font-important mb-4">
                    <Link to={node.path.alias} className="text-gray-500 md:text-white">{node.title}</Link>
                </div>

                <div className="link hidden md:block">
                    <Link to={node.path.alias} className="button button-white">En lire plus</Link>
                </div>
            </div>
        </>
    )
}

export default BannerOrA4;