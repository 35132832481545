import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import Truncate from "../Truncate";
import A4 from "../Images/A4";
import Small16by10 from "../Images/Small16by10";

const EventListItemAlternative = ({content}) => (
    <div className="event-list-item-alternative card relative h-full">
        <div className="card-image relative flex-initial">
            <Link to={content.path.alias}>
                <div className="hidden lg:block">
                    {content.relationships.verticalImage ? (
                        <GatsbyImage
                            image={content.relationships.verticalImage.localFile.childImageSharp.gatsbyImageData}
                            alt={content.title} 
                            className="w-full"
                        />
                    ) : (
                        <A4 
                            alt={content.title}
                            className="w-full"
                        />
                    )}
                </div>
                <div className="block lg:hidden">
                    {content.relationships.image ? (
                        <GatsbyImage
                            image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                            alt={content.title} 
                            className="w-full"
                        />
                    ) : (
                        <Small16by10 
                            alt={content.title}
                            className="w-full"
                        />
                    )}
                </div>
            </Link>
        </div>
        <div className="group card-content flex flex-col bg-primary-light lg:bg-transparent lg:bg-gradient-to-t from-primary-light hover:from-secondary static lg:absolute inset-0 p-4 transition-all duration-300">
            <div className="flex-1 lg:my-4 lg:-mx-4 absolute lg:static top-0 left-0">
                <div className="inline-block py-2 px-4 bg-secondary text-white text-center">
                    <span className="text-4xl block leading-none">{content.dates.startDay}</span>
                    <span className="font-semibold block lowercase leading-none">{content.dates.startMonth}</span>
                </div>
            </div>

            <div className="flex-initial">
                <div className="title h3 mb-4 text-white hover:text-primary transition-all">
                    <Link to={content.path.alias} className="group-hover:text-white hover:text-white">{content.title}</Link>
                </div>
            </div>

            <div className="flex-initial overflow-hidden w-auto max-h-0 lg:group-hover:max-h-96 transition-all duration-300">
                <Link to={content.path.alias} className="button button-secondary bg-white mt-4">En lire plus</Link>
            </div>
        </div>
    </div>
)

EventListItemAlternative.propTypes = {
    content: PropTypes.object.isRequired,
}

export default EventListItemAlternative
