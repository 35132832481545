import React, {useEffect, useState, useRef} from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import {useSwipeable} from "react-swipeable"
import BannerOrA4 from "../Images/BannerOrA4"

const ArticlesSlideshowPreview = ({autoplay, autoplaySpeed = 5000}) => {
    const data = useStaticQuery(graphql`{
        articles: allNodeArticle(
          filter: {status: {eq: true}, promote: {eq: true}},
          sort: {fields: [field_display_date, created], order: DESC}
          limit: 4
        ) {
            nodes {
                title
                body {
                    summary
                    value
                }
                path {
                    alias
                }
                image: field_image {
                    alt
                    width
                    height
                }
                relationships {
                    image: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(height: 470, quality: 100, layout: CONSTRAINED)
                            }
                        }
                    }
                    imageBackground: field_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 968
                                    height: 500
                                    quality: 100
                                    transformOptions: {cropFocus: CENTER}
                                    layout: CONSTRAINED
                                )
                            }
                        }
                    }
                    tags: field_tags {
                        name
                        color: field_color
                        path {
                            alias
                        }
                    }
                }
            }
        }       
    }`)

    const first = 0;
    const last = data.articles.nodes.length - 1;
    const imageWrapperRef = useRef();
    const [active, setActive] = useState(first);
    

    const next = () => {
        setActive(active === last ? first : active + 1);
    }

    const previous = () => {
        setActive(active === first ? last : active - 1);
    }

    const swipeHandler = useSwipeable({
        onSwipedLeft: eventData => next(),
        onSwipedRight: eventData => previous()
    })

    useEffect(() => {
        if (autoplay) {
            const timeout = setTimeout(() => {
                next();
            }, autoplaySpeed);
    
            return () => clearTimeout(timeout);
        }
    }, [])

    useEffect(() => {
        if (autoplay) {
            const timeout = setTimeout(() => {
                next();
            }, autoplaySpeed);
    
            return () => clearTimeout(timeout);
        }
    }, [active])

    return (
        <>
            <div className="relative lg:h-full pb-12 md:pb-0 lg:flex lg:flex-col">
                <div className="relative lg:flex-1" {...swipeHandler}>
                    <div ref={imageWrapperRef} className="h-full">
                        {data.articles.nodes.map((node, index) => (
                            <div key={index} className={`article-slideshow-list-item relative h-full${index === active ? ` active` : ` hidden`}`}>
                                <BannerOrA4 node={node} wrapperRef={imageWrapperRef}/>
                            </div>
                        ))}
                    </div>
                    <div className="hidden md:block absolute inset-0 bg-gradient-to-t from-primary-light to-white mix-blend-multiply"></div>
                </div>

                <div className="block xl:hidden">
                    <button
                        className="previous absolute bottom-0 m-1 md:top-1/2 right-1/2 md:right-auto md:left-0 flex items-center justify-center h-8 w-8 bg-primary border-2 border-primary rounded-full text-white z-20"
                        onClick={previous}
                    >
                        <i className="fas fa-chevron-left"><span className="hidden">Previous</span></i>
                    </button>

                    <button
                        className="next absolute bottom-0 m-1 md:top-1/2 left-1/2 md:left-auto md:right-0 flex items-center justify-center h-8 w-8 bg-primary border-2 border-primary rounded-full text-white z-20"
                        onClick={next}
                    >
                        <i className="fas fa-chevron-right"><span className="hidden">Next</span></i>
                    </button>
                </div>

                <div className="article-slideshow-navigation hidden xl:block">
                    <div className="columns flex flex-row">
                        {data.articles.nodes.map((node, index) => (
                            <div key={index} className={`column flex-1 cursor-pointer${index === active ? ` active` : ``}`} onClick={() => setActive(index)}>
                                <div className="article-slideshow-navigation-list-item group relative h-full flex items-center p-4 bg-primary-light hover:bg-primary transition-all duration-300">
                                    <div className="title font-semibold font-important">
                                        <span className="text-white transition-all duration-300">{node.title}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="more-link lg:hidden text-center mt-8">
                <Link to="/actualites" className="button button-primary-light bg-white">Voir toutes les actualités</Link>
            </div>
        </>
    )
}

export default ArticlesSlideshowPreview
