import React, {useEffect, useState} from "react";
import {graphql, Link, useStaticQuery} from "gatsby";

import EventListItemAlternative from "../ListItems/EventListItemAlternative";

const EventsPreview = () => {
    const data = useStaticQuery(graphql`{
  events: allNodeEvenement(
    filter: {is_forthcoming: {eq: true}, status: {eq: true}}
    sort: {fields: [field_date___value, field_date___end_value], order: ASC}
    limit: 4
  ) {
    nodes {
      title
      path {
        alias
      }
      dates: field_date {
        startDay: value(formatString: "DD", locale: "fr")
        startMonth: value(formatString: "MMM", locale: "fr")
      }
      body {
        summary
        value
      }
      relationships {
        verticalImage: field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 458
                height: 647
                quality: 100
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
        image: field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 352
                height: 222
                quality: 100
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`)
    return (
        <>
            {data.events.nodes.length > 0 ? (
              <div className="section">
                <div className="container mx-auto px-4">
                  <div className="relative p-8">
                    <div className="half-bg absolute h-80 lg:h-1/2 top-0 inset-x-0 bg-gradient-to-r from-primary to-primary-light">&nbsp;</div>

                    <h2 className="title h2 relative text-white mb-8">Agenda</h2>

                    <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                      {data.events.nodes.map((node, index) => (
                        <div key={index} className="column">
                          <EventListItemAlternative content={node}/>
                        </div>
                      ))}
                    </div>

                    <div className="more-link lg:absolute top-0 right-0 lg:p-8 mt-8 lg:mt-0 text-center lg:text-left z-20">
                      <Link to="/evenements" className="button button-primary-light bg-white">Voir tout l'agenda</Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default EventsPreview
