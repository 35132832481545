import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import ArticleListItem from "../ListItems/ArticleListItem";

const ArticlesPreview = () => {
    const data = useStaticQuery(graphql`{
      articles: allNodeArticle(
        filter: {status: {eq: true}, promote: {eq: true}}
        sort: {fields: [field_display_date, created], order: DESC}
        limit: 4,
        skip: 4
      ) {
        nodes {
          title
          body {
            summary
            value
          }
          path {
            alias
          }
          day: field_display_date(formatString: "DD", locale: "fr")
          month: field_display_date(formatString: "MMM", locale: "fr")
          year: field_display_date(formatString: "YY", locale: "fr")
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 352
                    height: 222
                    quality: 100
                    transformOptions: {cropFocus: CENTER}
                    layout: CONSTRAINED
                  )
                }
              }
            }
            tags: field_tags {
              name
              color: field_color
              path {
                alias
              }
            }
          }
        }
      }
    }
  `)


  return (
    <>
      {data.articles.nodes.length > 0 && (
        <div className="relative">
          <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {data.articles.nodes.map((node, index) => (
              <div key={index} className={`column`}>
                <ArticleListItem content={node}/>
              </div>
            ))}
          </div>
          <div className="more-link text-center mt-8">
            <Link to="/actualites" className="button button-primary-light bg-white">Voir toutes les actualités</Link>
          </div>
        </div>
      )}
    </>
  )
}

export default ArticlesPreview
