import React from "react";
import QuickAccessMenu from "../Menus/QuickAccessMenu";

const QuickAccess = ({limit}) => {
    return (
        <div className="bg-primary-light h-full py-8 px-4">
            <h2 className="title h2 text-white mb-8 px-4">En un clic</h2>
            <QuickAccessMenu limit={limit}/>
        </div>
    )
}

export default QuickAccess
