import React from "react";

import Layout from "../components/Layout";
import Metas from "../components/Metas";
import ArticlesSlideshowPreview from "../components/Blocks/ArticlesSlideshowPreview";
import ArticlesPreview from "../components/Blocks/ArticlesPreview";
import EventsPreview from "../components/Blocks/EventsPreview";
import QuickAccess from "../components/Blocks/QuickAccess";

const IndexPage = () => (
    <Layout isHome>
        <Metas title="Accueil" />
        <h1 className="hidden">Commune de Gespunsart</h1>
        <div id="articles-preview--block" className="section">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
                    <div className="xl:col-span-2 h-full">
                        <ArticlesSlideshowPreview autoplay={true}/>
                    </div>
                    <div>
                        <QuickAccess limit={6}/>
                    </div>
                    <div className="hidden lg:block xl:col-span-3">
                        <ArticlesPreview/>
                    </div>
                </div>
            </div>
        </div>
        <div id="events-preview--block">
            <EventsPreview/>
        </div>
    </Layout>
)

export default IndexPage;
